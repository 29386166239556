import React, { useState } from "react";
import { Nav } from "react-bootstrap";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import "react-html5video/dist/styles.css";
import SecondaryMenu from "./SecondaryMenu";
import ParallaxSmallBanner from "../SubComponents/ParallaxSmallBanner";
import Img1 from "./../Images/alain-pham-P_qvsF7Yodw-unsplash.jpg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Tab from "react-bootstrap/Tab";
import coreimg2 from "./../Images/core principles/a011dc2a-e224-4ff0-975c-b49f3e2f632f.jpg";
import Purpose from "../Images/purpose-1.jpg";
import Goals from "../Images/goals.jpg";
import Mission from "../Images/mission.jpg";
import Vision from "../Images/vision.jpg";
import Values from "../Images/values.jpg";
import London from '../Images/cities/london.png';
import Angus from '../Images/cities/angus.png';
import Brantford from '../Images/cities/brantford.png';
import Cambridge from '../Images/cities/cambridge.png';
import Scarborough from '../Images/cities/scarborough.png';
import Waterloo from '../Images/cities/waterloo.png';
import Windsor from '../Images/cities/windsor.png';
const OfficeLocationOutlet = (props) => {
  const [toggleValues, setToggleValues] = useState(false);
  const [toggleGoals, setToggleGoals] = useState(false);
  const [toggleMission, setToggleMission] = useState(false);
  const [toggleVision, setToggleVision] = useState(false);
  const [togglePurpose, setTogglePurpose] = useState(false);
  function handleTogglePurpose() {
    setTogglePurpose((prev) => !prev);
  }
  function handleToggleValues() {
    setToggleValues((prev) => !prev);
  }
  function handleToggleGoals() {
    setToggleGoals((prev) => !prev);
  }
  function handleToggleMission() {
    setToggleMission((prev) => !prev);
  }
  function handleToggleVision() {
    setToggleVision((prev) => !prev);
  }
  return (
    <>
      <Header />
      <div className="scrll">
        <ParallaxSmallBanner img={Img1} title="Our Outlets" />
      </div>

      <div className="pos-rel">
        <div className="pad-60 py-5">
          <div className="pt-2 pb-5 text-center">
            <SecondaryMenu linkName={props.linkName} />
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-6">
                <div className="world-map">
                  <img src={require('../Images/world-map1.png')} alt="World Map" className="img-fluid" />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="office-details">
                  <h5><span className="text-primary">Our Presence</span></h5>
                  <h2 className="main-title">Main Office</h2>
                  
                  <div className="location-list mt-4">
                    <div className="location-item mb-4">
                      <h4>Canada</h4>
                      <p>444 Elgin st, Brantford, ON N3S 7P7, Canada</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            
            <div className="row mt-5 mb-5">
              <h2 className="text-center mb-5">Biryani Bar's Franchise outlets</h2>
              <div className="col-md-3 mb-4">
                <div className="branch-office text-center">
                  <img src={London} alt="Jaipur" className="city-image mb-3" />
                  <h5>London</h5>
                </div>
              </div>
              <div className="col-md-3 mb-4">
                <div className="branch-office text-center">
                  <img src={Angus} alt="Kolkata" className="city-image mb-3" />
                  <h5>Angus</h5>
                </div>
              </div>
              <div className="col-md-3 mb-4">
                <div className="branch-office text-center">
                  <img src={Cambridge} alt="Chennai" className="city-image mb-3" />
                  <h5>Cambridge</h5>
                </div>
              </div>
              <div className="col-md-3 mb-4">
                <div className="branch-office text-center">
                  <img src={Scarborough} alt="Dubai" className="city-image mb-3" />
                  <h5>Scarborough</h5>
                </div>
              </div>
              <div className="col-md-3 mb-4">
                <div className="branch-office text-center">
                  <img src={Brantford} alt="Bangladesh" className="city-image mb-3" />
                  <h5>Brantford</h5>
                </div>
              </div>
              <div className="col-md-3 mb-4">
                <div className="branch-office text-center">
                  <img src={Waterloo} alt="London" className="city-image mb-3" />
                  <h5>Waterloo</h5>
                </div>
              </div>
              <div className="col-md-3 mb-4">
                <div className="branch-office text-center">
                  <img src={Windsor} alt="Clinton" className="city-image mb-3" />
                  <h5>Windsor</h5>
                </div>
              </div>
              
            </div>
          </div>

        </div>
        <Footer />
      </div>
    </>
  );
};

export default OfficeLocationOutlet;
