import { BrowserRouter, Routes, Route } from "react-router-dom";
import { lazy } from "react";
import Home from "../Pages/Home";
import Products from "../Pages/Products";
import Contact from "../Pages/Contact";
import Projects from "../Pages/Projects";
import ScrollToTop from "./ScrollToTop"; 
import Careers from "../Pages/Careers";
import Enquiry from "../Pages/Enquiry";
import PEBStructures from "../Pages/PEBStructures";
// import RealEstate from "../Pages/RealEstate";
import RealEstate from "../Pages/RealState/index";
import SustainableServices from "../Pages/SustainableServices";
// import BIMServices from "../Pages/BIMServices";
import BIMServices from "../Pages/BimServices/index";
// Remove duplicate import since BIMServices is already imported above

// import StructuralAudit from "../Pages/StructuralAudit";
import StructuralAudit from "../Pages/Structural Audit/index";
import Blog from "../Pages/Blog";
import GetToKnow from "../AboutComponents/GetToKnow";
import Principles from "../AboutComponents/Principles";
import OfficeLocationOutlet from "../AboutComponents/officeLocation";
import OurTeam from "../AboutComponents/OurTeam";
import Achievements from "../AboutComponents/Achievements";
import Certifications from "../AboutComponents/Certifications";
import FeaturedNews from "../AboutComponents/FeaturedNews";
import SoftwareAssociates from "../AboutComponents/SoftwareAssociates";

import AboutCareer from "../AboutComponents/about-career";
import AboutContact from "../AboutComponents/about-contact";

import ProductDetails from "../Pages/ProductDetails";
import Signup from "../Pages/Signup";
import Login from "../Pages/Login"; 
import Cart from "../Pages/Cart";
import OilAndGas from "../Pages/OilAndGas";
import IbLibrary from "../Pages/IbLibrary";
import PebStructure from "../Pages/PebStucture";
import ProjectView from "../Pages/ProjectView";
import ProjectView1 from "../Pages/ProjectView1";
import ProjectView2 from "../Pages/ProjectView2";
import ProjectView3 from "../Pages/ProjectView3";
import ProjectView4 from "../Pages/ProjectView4";
import ProjectView5 from "../Pages/ProjectView5";
import PageNotFound from "../Pages/PageNotFound";
import Projectviewrealstate from "../Pages/ProjectViewRealState";
import Sector5 from "../Pages/Sector5/sector5";

// const Home = lazy(() => import("../Pages/Home"));
// const Products = lazy(() => import("../Pages/Products"));
// const Contact = lazy(() => import("../Pages/Contact"));
// const Projects = lazy(() => import("../Pages/Projects"));
// const ScrollToTop = lazy(() => import("./ScrollToTop"));
// const Careers = lazy(() => import("../Pages/Careers"));
// const Enquiry = lazy(() => import("../Pages/Enquiry"));
// const PEBStructures = lazy(() => import("../Pages/PEBStructures"));
// const RealEstate = lazy(() => import("../Pages/RealEstate"));
// const SustainableServices = lazy(() => import("../Pages/SustainableServices"));
// const BIMServices = lazy(() => import("../Pages/BIMServices"));
// const StructuralAudit = lazy(() => import("../Pages/StructuralAudit"));
// const Blog = lazy(() => import("../Pages/Blog"));
// const GetToKnow = lazy(() => import("../AboutComponents/GetToKnow"));
// const Principles = lazy(() => import("../AboutComponents/Principles"));
// const OurTeam = lazy(() => import("../AboutComponents/OurTeam"));
// const Achievements = lazy(() => import("../AboutComponents/Achievements"));
// const Certifications = lazy(() => import("../AboutComponents/Certifications"));
// const FeaturedNews = lazy(() => import("../AboutComponents/FeaturedNews"));
// const SoftwareAssociates = lazy(() => import("../AboutComponents/SoftwareAssociates"));
// const ProductDetails = lazy(() => import("../Pages/ProductDetails"));
// const Signup = lazy(() => import("../Pages/Signup"));
// const Login = lazy(() => import("../Pages/Login"));
// const Cart = lazy(() => import("../Pages/Cart"));
// const OilAndGas = lazy(() => import("../Pages/OilAndGas"));

export default function RouterPage() {
  return (
    <div>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" exact element={<Home />}></Route>

          <Route path="/frozen-food" element={<OilAndGas />}></Route>
          <Route path="/restaurant" element={<PebStructure />}></Route>
          <Route path="/bg-pharma" element={<RealEstate />}></Route>
          <Route path="/sector-5" element={<Sector5/>}></Route>
          {/* <Route
            path="/sustainable-projects"
            element={<SustainableServices />}
          ></Route> */}
          <Route path="/real-estate" element={<BIMServices />}></Route>
          
          {/* <Route path="/structural-audit" element={<StructuralAudit />}></Route> */}

          <Route path="/products" element={<Products />}></Route>
          <Route path="/contact" element={<Contact />}></Route>
          <Route path="/about" element={<GetToKnow linkName="about" />}></Route>
          <Route
            path="/principles"
            element={<Principles linkName="principles" />}
          ></Route>
           <Route
            path="/officeslocation"
            element={<OfficeLocationOutlet linkName="officeslocation" />}
          ></Route>
          <Route
            path="/our-team"
            element={<OurTeam linkName="our-team" />}
          ></Route>
          <Route
            path="/achievements"
            element={<Achievements linkName="achievements" />}
          ></Route>
          <Route
            path="/certifications"
            element={<Certifications linkName="certificates" />}
          ></Route>
          {/* <Route
            path="/featured-news"
            element={<FeaturedNews linkName="featured-news" />}
          ></Route> */}
          {/* <Route
            path="/software-associates"
            element={<SoftwareAssociates linkName="software-associates" />}
          ></Route> */}

          {/* <Route
            path="/about-contact"
            element={<AboutContact linkName="about-contact" />}
          ></Route> */}
          {/* <Route
            path="/about-career"
            element={<AboutCareer linkName="about-career" />}
          ></Route> */}


          <Route path="/product-details" element={<ProductDetails />}></Route>
          <Route path="/projectview" element={<ProjectView />}></Route>
          <Route path="/projectview1" element={<ProjectView1 />}></Route>
          <Route path="/projectview2" element={<ProjectView2 />}></Route>
          <Route path="/projectview3" element={<ProjectView3 />}></Route>
          <Route path="/projectview4" element={<ProjectView4 />}></Route>
          <Route path="/projectview5" element={<ProjectView5 />}></Route>
          <Route path="/projectviewrealstate" element={<Projectviewrealstate />}></Route>
  


          <Route path="/projects" element={<Projects />}></Route>
          <Route path="/careers" element={<Careers />}></Route>
          {/* <Route path="/iblibrary" element={<IbLibrary />}></Route> */}
          {/* <Route path="/enquiry" element={<Enquiry />}></Route> */}
          {/* <Route path="/blogs" element={<Blog />}></Route> */}
          {/* <Route path="/cart" element={<Cart />}></Route> */}

          {/* <Route path="/signup" element={<Signup />}></Route>
          <Route path="/login" element={<Login />}></Route> */}
          <Route path="*" element={<PageNotFound />} />        </Routes>
      </BrowserRouter>
    </div>
  );
}
