import { React, useState } from "react";
import { NavLink, Link } from "react-router-dom";
// import logo from "./../Images/website-images/intel-logo.png";
// import logo from "./../Images/logo/129299338_padded_logo(1).png";
import logo from "./../Images/logo/logo.v2.png";
import { GrClose } from "react-icons/gr";
import { FiChevronDown } from "react-icons/fi";

const Header = () => {
  const [isOpen, setOpen] = useState(false);
  const [subMenu, setsubMenu] = useState(false);
  const toggleOpen = () => {
    setOpen(!isOpen);
  };
  const toggleSubMenu = () => {
    setsubMenu(!subMenu);
  };

  window.addEventListener("scroll", function () {
    var scrolled =
      document.documentElement.scrollTop || document.body.scrollTop;
    var signup = document.getElementById("new-sign-up");

    if (scrolled > 150) {
      signup.style.backgroundColor = "";
    } else {
      signup.style.backgroundColor = "";
    }
  });

  return (
    <>
      <header className="header">
        <div className="container-fluid px-lg-0">
          <div className="d-flex justify-content-between">
            <div className="d-flex align-items-center">
              <Link className="navbar-brand" to="/">
                <img className="logo-img" src={logo}alt="" />
              </Link>
            </div>

            <div
              className={isOpen ? "menu-overlay active" : "menu-overlay"}
            ></div>
            <div className="d-flex align-items-center">
              <nav
                className={
                  isOpen
                    ? "open nav-menu uk-navbar-right"
                    : "nav-menu uk-navbar-right"
                }
              >
                <div>
                  <Link
                    className="close-nav-menu navbar-brand1"
                    onClick={toggleOpen}
                    to=""
                  >
                    <GrClose />
                  </Link>
                </div>
                <div className="d-flex align-items-center">
                  <ul className="menu ps-0" style={{ marginBottom: "0px" }}>
                    <li className="menu-item .bn40::after">
                      <NavLink
                        to="/about"
                        className={({ isActive }) => (isActive ? "active" : "")}
                      >
                        About Us
                      </NavLink>
                    </li>
                    <li className="menu-item">
                      <NavLink
                        to="/products"
                        className={({ isActive }) => (isActive ? "active" : "")}
                        style={{ textTransform: "none" }}
                      >
                        Frozen Food
                      </NavLink>
                    </li>

                    <li className="menu-item menu-item-has-children">
                      <NavLink
                        className={({ isActive }) => (isActive ? "" : "active")}
                        to=""
                        onClick={toggleSubMenu}
                      >
                        Sectors{" "}
                        <span className="plus">
                          <FiChevronDown />
                        </span>
                      </NavLink>
                      <ul
                        className={
                          subMenu
                            ? "sub-menu min-250 sub-open"
                            : "sub-menu min-250"
                        }
                      >
                        <li className="menu-item menu-item-has-children-inner">
                          <Link
                            to="/frozen-food"
                            title="Investment in Real Estate Land Devlopment"
                          >
                           Frozen Food
                          </Link>
                        </li>
                        <li className="menu-item ">
                          <NavLink to="/restaurant">Restaurant </NavLink>
                        </li>
                        <li className="menu-item ">
                          <NavLink to="/bg-pharma">Pharma </NavLink>
                        </li>
                        <li className="menu-item ">
                          <NavLink to="/real-estate">Real Estate</NavLink>
                        </li>
                        <li className="menu-item ">
                          <NavLink to="/sector-5">B & G Foundation</NavLink>
                        </li>
                        {/* <li className="menu-item ">
                          <NavLink to="/sustainable-projects">
                            Sustainable Design &amp; Engineering
                          </NavLink>
                        </li>
                        <li className="menu-item ">
                          <NavLink
                            to="/structural-audit"
                            style={{ textTransform: "none" }}
                          >
                            Structural Audit
                          </NavLink>
                        </li> */}
                      </ul>
                    </li>

                    <li className="menu-item">
                      <NavLink
                        className={({ isActive }) => (isActive ? "active" : "")}
                        to="/projects"
                      >
                        Portfolio
                      </NavLink>
                    </li>
                  </ul>
                </div>

                <div>
                  <Link
                    className="close-nav-menu navbar-brand1"
                    onClick={toggleOpen}
                    to=""
                  >
                    <i className="fas fa-times"></i>
                  </Link>
                </div>
              </nav>
              <div
                style={{
                  display:
                    document.documentElement.scrollTop ||
                    document.body.scrollTop
                      ? "none"
                      : "block",
                }}
              >
                <button
                  className="navbar-toggler d-none"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarNav"
                  aria-controls="navbarNav"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <i className="fa-solid fa-bars-staggered"></i>
                </button>
              </div>
            </div>
            <div className="d-flex contact-desk align-items-center">
              <div className="bn40div bg-color-primary">
                <Link
                  className="new-sign-up "
                  id="new-sign-up"
                  to="/signup"
                  style={{
                    fontFamily: "artifak , Arial, Helvetica, sans-serif",
                  }}
                >
                  {" "}
                  {/* Sign Up */}
                </Link>
              </div>
            </div>
            <div className="open-nav-menu">
              <Link className="signup-icon" to="/signup">
                <i className="bi bi-person-circle"></i>
              </Link>
              <span onClick={toggleOpen}>
                <i className="fa-solid fa-bars"></i>
              </span>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
