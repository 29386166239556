import React, { useContext } from 'react'
import Header from '../Components/Header'
import Footer from '../Components/Footer'
import RealStateProjectdata from '../Data/RealStateProjectsData'
import { Link } from 'react-router-dom';
import DataContext from '../Context/bediContext';

const Projectviewrealstate = () => {
    const {Index} = useContext(DataContext)
    return (
        <>
            <div style={{ backgroundColor: '#020202', height: '80px', width: "100%" }}></div>
            <Header />
            <Link to="/real-estate"><button id='backBtn'>Back</button></Link>
            <div className='topRealstateP'>
                <h1 className='projectReal'>{RealStateProjectdata[Index].name}</h1>
                <div className='mainImageDiv'>
                    <img src={RealStateProjectdata[Index].mainImg} />
                </div>
            </div>
            <div className='insideView'>
                <h5>InsideView</h5>
                <div className='projectImages'>
                    <img src={RealStateProjectdata[Index].oneImg} />
                    <img src={RealStateProjectdata[Index].twoImg} />
                    <img src={RealStateProjectdata[Index].threeImg} />
                    <img src={RealStateProjectdata[Index].fourImg} />
                    <img src={RealStateProjectdata[Index].fiveImg} />
                    <img src={RealStateProjectdata[Index].sixImg} />
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Projectviewrealstate