import React from "react";
import abt1 from "./../Images/website-images/1b.jpeg";
import abt2 from "./../Images/website-images/3b.jpeg";
import abt3 from "./../Images/website-images/2b.jpeg";
import abt4 from "./../Images/website-images/4b.jpeg";
import blog1 from "./../Images/Images/ablog1.jpeg";
import Frame2 from "../Pages/frame-2";
import Frame from "../Pages/frame";
// import { Link } from 'react-router-dom';

function Blogs() {
  return (
    <>
      <div className="row">
      <div className="row">
  <div className="col-lg-6">
    <a href="#" target="_blank" className="blog-box blog-bx">
      <div className="blog-img">
        <img src={blog1} alt="" />
      </div>
      <div className="blog-date">
        <span></span>
      </div>
      <div className="blog-text mt-5">
        <h1></h1>
        <p></p>
      </div>
    </a>
  </div>
  <div className="col-lg-6">
    
      
      <div className="blog-date">
        <span></span>
      </div>
      <div className="blog-text">
        <h1>Biryani Bar by Bedi Group opens doors to Canada and global markets, offering lucrative franchise opportunities. Our franchise model is designed to maximize returns on your investment, ensuring success with an authentic and in-demand culinary brand.</h1><br/>
        
      </div>
    
  </div>
</div>
      </div>

      <div className="row">
        

        <div className="row w-100 mx-0">
         
        </div>
        
      </div>
    </>
  );
}

export default Blogs;
