import React from "react";
import { Controller, Scene } from "react-scrollmagic";

const SectionWipes = (page) => {
  const titleStyle = {
    fontSize: 'clamp(2rem, 5vw, 3.5rem)',
    lineHeight: '1.2',
    textAlign: 'center',
    padding: '0 15px',
    width: '100%',
    maxWidth: '1200px',
    margin: '0 auto',
    wordWrap: 'break-word' 
  };

  return (
    <div className="over-hide">
      <Controller globalSceneOptions={{ triggerHook: "onLeave" }}>
        <Scene pin>
          <div
            className="panel blue"
            style={{ backgroundImage: `url(${page.img})` }}
            id="popup"
          >
            <span 
              className={page.title === 'Trusted & Secure Real Estate Developer.' ? "hero-title realStateHeading" : "hero-title"}
              style={titleStyle}
            >
              {page.title}
            </span>
          </div>
        </Scene>
      </Controller>
    </div>
  );
};

export default SectionWipes;
