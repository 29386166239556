import OilGas1 from './../Images/oil-gas1.png';

const ProductsData =[
    {
        id:1,
        service:"B&G Foundation",
        product:"B&G Foundation",
        category:"B & G Foundation",
        image: "./bg-f.jpg",  // Changed to use require
        span: "The B&G Foundation is dedicated to making positive social impact through various initiatives."
    }
];

export default ProductsData;