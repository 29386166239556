import React, { Fragment, useContext, useEffect, useState } from "react";
import "./index.css";
import Header from "../../Components/Header";
import Banner from "../../SubComponents/Banner";
import PebService from "../../Images/peb-background.jpg";
import { Col, Form, Row } from "react-bootstrap";
import PubServiceContent from "../../Images/Bim2.gif";
import { Link } from "react-router-dom";
import { structuredData } from "./data";
import rightArrow from "../../Images/icons/right-arrow.svg";
import leftArrow from "../../Images/icons/left-arrow.svg";
import CustomCarousal from "./CustomCarousel";
import PEBImg1 from "../../Images/peb/peb-1.png";
import PEBImg2 from "../../Images/peb/peb-2.png";
import PEBImg3 from "../../Images/peb/peb-3.png";
import PEBImg4 from "../../Images/peb/peb-4.png";
import SectionWipes from '../../SubComponents/ParallaxHero'
// import BIMbg from "../../Images/header/bim.jpg";
// import Oilgas22 from '../../Images/2product.png';
import Oilgas22 from '../../Images/Images/website-images/BIM-pics/20CH.jpeg';
import imgOne from '../../Images/RealState/oneBox.svg'
import imgTwo from '../../Images/RealState/twoBox.svg'
import imgThree from '../../Images/RealState/threeBox.svg'
import imgFour from '../../Images/RealState/fourBox.svg'
import Footer from "../../Components/Footer";
import bannerImg from '../../Images/RealState/193D.jpeg'
import projectOne from '../../Images/RealState/projectOne.jpeg'
import projectTwo from '../../Images/RealState/projectTwo.jpeg'
import projectThree from '../../Images/RealState/projectThree.jpeg'
import projectFour from '../../Images/RealState/projectFour.jpeg'
import fourthBox from '../../Images/RealState/fourthBox.jpeg'
import founder from '../../Images/RealState/1founder.c126cdfd37af98a165e1.jpg'
import mam from '../../Images/RealState/1marketing.14f62836679f4c2a6a5d.jpeg'
import niharika from '../../Images/RealState/niharikaImg.png'
import DataContext from "../../Context/bediContext";
import biryaniBar from "../../Images/RealState/biryaniBarFive.jpeg"
import AOS from 'aos';
import 'aos/dist/aos.css'; 

const Sector = () => {
  useEffect(() => {
    AOS.init();
}, []);
  const [stucturedSlide, setStucturedSlide] = useState(0);
  const [active, setActive] = useState("right");

  const handleStucturedSlide = (index) => {
    setStucturedSlide(index);
  };
  const handleSlideByArrow = (value) => {
    setActive(value);
    if (value === "left" && stucturedSlide > 0) {
      setStucturedSlide(stucturedSlide - 1);
    } else {
      console.log(
        "enter",
        !structuredData?.length > stucturedSlide,
        structuredData?.length,
        stucturedSlide
      );
      if (structuredData?.length - 1 > stucturedSlide) {
        setStucturedSlide(stucturedSlide + 1);
      }
    }
  };
  const sectionsData = [
    {
      content1: {
        strong: "Design and Engineering:",
        paragraph:
          "Pre-engineered buildings are designed and engineered off-site using STAADPro software, ensuring precise and efficient manufacturing",
      },
      content2: {
        imgSrc: PEBImg1,
        imgAlt: "PEB Image 1",
      },
      content3: {
        strong: "Design and Engineering:",
        paragraph:
          "PEB's are manufactured in a controlled factory environment, with building components pre-cut, pre-drilled, and ready for assembly on-site. This leads to faster construction timelines and reduced labor requirements.",
      },
    },
    {
      content1: {
        strong: "Construction Process:",
        paragraph:
          "PEB's are manufactured in a controlled factory environment, with building components pre- cut, pre-drilled, and ready for assembly on-site. This leads to faster construction timelines and reduced labor requirements.",
      },
      content2: {
        imgSrc: PEBImg2,
        imgAlt: "PEB Image 2",
      },
      content3: {
        strong: "Construction Process:",
        paragraph:
          "Hot rolled buildings involve the fabrication and assembly of structural steel components on-site, which can be more time-consuming and labor-intensive.",
      },
    },
    {
      content1: {
        strong: "Cost and Efficiency:",
        paragraph:
          "The streamlined manufacturing process, reduced construction time, and minimized material waste contribute to cost savings. Member sizes are tailored after technically analyzing the member, further reducing construction costs.",
      },
      content2: {
        imgSrc: PEBImg3,
        imgAlt: "PEB Image 3",
      },
      content3: {
        strong: "Cost and Efficiency:",
        paragraph:
          "Hot rolled buildings, on the other hand, may have higher material and labor costs due to not being able to pr and longer construction timelines",
      },
    },
    {
      content1: {
        strong: "Flexibility and Customization:",
        paragraph:
          "The streamlined manufacturing process, reduced construction time, and minimized material waste contribute to cost savings. Member sizes are tailored after technically analyzing the member, further reducing construction costs.",
      },
      content2: {
        imgSrc: PEBImg4,
        imgAlt: "PEB Image 4",
      },
      content3: {
        strong: "Flexibility and Customization:",
        paragraph:
          "Hot rolled buildings, while customizable to some extent, may require more extensive modifications and additional engineering work to accommodate changes after the initial construction",
      },
    },
  ];
  const { getIndex } = useContext(DataContext)
  return (
    <Fragment>
      <Header />
      <SectionWipes img={Oilgas22} title="Trusted & Secure Real Estate Developer." />
      <div className='pos-rel real-state-main'>
        <div className="container-section ">
          <div className="boxes">
            <div data-aos="fade-up" className="">
              <img src={imgOne} className="" alt="" />
              <strong>Superior Craftsmanship</strong>
            </div>
            <div data-aos="fade-up"  data-aos-duration="500" className="">
              <img src={imgTwo} className="" alt="" />
              <strong>On Time Deliveries</strong>
            </div>
            <div data-aos="fade-up"  data-aos-duration="500" className="">
              <img src={imgThree} className="" alt="" />
              <strong>Client-Centric Approach</strong>
            </div>
            <div data-aos="fade-up"  data-aos-duration="500" className="">
              <img src={imgFour} className="" alt="" />
              <strong>Transparent Communication</strong>
            </div>
          </div>
          <div className="secondBox">
            <div data-aos="fade-right"  data-aos-duration="500" className="secondOne">
              <h2>Seamless Real Estate Solutions with Bedi Group</h2>
            </div>
            <div data-aos="fade-left"  data-aos-duration="500" className="secondTwo">
              <p>
                Experience a streamlined approach to real estate with Bedi Group, where every step of the process is tailored to meet your needs.
              </p>
              {/*<Link to="/real-estate"><button>Get In Touch</button></Link> */}
            </div>
          </div>
          <div className="thirdBox">
            <img src={bannerImg} className="" alt="" />
          </div>
        </div>
      </div>
      <div className="our-projects pos-rel">
        <div className="our-headings">
          <h3>Our Projects</h3>
          <h1>Discover Our Signature Developments</h1>
        </div>
        <div>
          <div className="projects-view">
            <div className="projectsOne">
              <div>
                <img src={projectOne} className="" alt="" />
                <div className="productData">
                  <h4>1004 Colborne</h4>
                  <p>2022</p>
                  <Link to="/projectviewrealstate"><button onClick={() => getIndex(0)}>Know more ⟩</button></Link>
                </div>
              </div>
            </div>
            <div className="projectsTwo">
              <div>
                <img src={projectTwo} className="" alt="" />
                <div className="productData">
                  <h4 >193 Drummond...</h4>
                  <p>2023</p>
                  <Link to="/projectviewrealstate"><button onClick={() => getIndex(1)}>Know more ⟩</button></Link>
                </div>
              </div>
            </div>
            <div className="projectsThree">
              <div>
                <img src={projectThree} className="" alt="" />
                <div className="productData">
                  <h4>20 Charles...</h4>
                  <p>2023</p>
                  <Link to="/projectviewrealstate"><button onClick={() => getIndex(2)}>Know more ⟩</button></Link>
                </div>
              </div>
            </div>
            <div className="projectsFour">
              <div>
                <img src={projectFour} className="" alt="" />
                <div className="productData">
                  <h4>218 Mount Str...</h4>
                  <p>2023</p>
                  <Link to="/projectviewrealstate"><button onClick={() => getIndex(3)}>Know more ⟩</button></Link>
                </div>
              </div>
            </div>
            <div className="projectsFive">
              <div>
                <img src={biryaniBar} className="" alt="" />
                <div className="productData">
                  <h4>Biryani Bar</h4>
                  <p>2023</p>
                  <Link to="/projectviewrealstate"><button onClick={() => getIndex(4)}>Know more ⟩</button></Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="fourthBox pos-rel real-state-main">
        <img src={fourthBox} className="" alt="" />
        <div>
          <div style={{ width: '100%', gap: "10px" }}>
            <p >WHY CHOOSE US ?</p>
            <h4>Efficient Project Management.</h4>
          </div>
          <p>At Bedi Group we have set of core values, principles, and ethics that guide our operations and define our commitment to excellence. At the heart of our approach lies a dedication to innovation and timely deliveries, ensuring that we not only meet but exceed the expectations of our customers.</p>
        </div>
      </div>
      <div className="fifthBox pos-rel real-state-main">
        <div className="ourTeamHeading">
          <h2>Our Dedicated Team.</h2>
          <Link to="/our-team" className="ourTeambtn">More about team</Link>
        </div>
        <div className="ImagesContainer">
          <div>
            <img src={founder} className="" alt="" />
            <div className="aboutText">
              <div>
                <h5>Mr Bedi</h5>
                <p>President & </p>
              </div>
            </div>
          </div>
          <div>
            <img src={mam} className="" alts="" />
            <div className="aboutText">
              <div>
                <h5>Sonam Jain</h5>
                <p>Marketing</p>
              </div>
            </div>
          </div>
          <div>
            <img src={niharika} className="" alt="" />
            <div className="aboutText">
              <div>
                <h5>Niharika Kaur</h5>
                <p>Account & Supply Chain</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="hideSpace">
          <div></div>
        </div>
        <div className="pos-rel real-state-main overview" style={{ textAlign: "center" }}>
          <div className="overview-title">
            <h3>OVERVIEW</h3>
            <h1 className="heading-text">
              On <span> Real Estate</span>
            </h1>
          </div>
          <p className="mb-4">“Bedi Group Real Estate” is a North American-based real estate company with a rich history and a strong track record. We specialize in developing, managing, and investing in properties across the region.</p>
          <p className="mb-4">With numerous successful projects under our belt, we pride ourselves on our ability to deliver high-quality, sustainable developments that enhance communities and create value for our clients. Our team of experienced professionals manages every aspect of the process, from site selection and acquisition to design, construction, and property management.</p>
          <p className="mb-4">We are committed to excellence in all that we do and strive to exceed expectations at every stage of the project. Our comprehensive approach ensures that we take care of everything, allowing our clients to enjoy a seamless and stress-free experience.</p>
          <p className="mb-4">Whether you’re looking to invest in real estate or need a trusted partner for your next project, “Bedi Group Real Estate” is here to help. We look forward to working with you to make your real estate dreams a reality. </p>
          <div style={{ textAlign: "center", padding: "20px 0px" }}>
            <Link className="nav-btn me-4 custom-btn quote" to="/">
              Get A Quote
            </Link>
          </div>
        </div>
      </div>

      <Footer />
    </Fragment>
  );
};

export default Sector;
