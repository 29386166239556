import OilGas1 from './../Images/oil-gas1.png';

// import OilGas2 from 'assets/Img/oil-gas2.png';


const ProductsData =[
    // {
    //     id:1,
    //     service:"Ammonia plant",
    //     product:"Ammonia plant",
    //     category:"oil-and-gas",
    //     image:"assets/website-images/Oil-and-Gas-pics/ammonia-plant.jpg"
    // },
    // {
    //     id:2,
    //     service:"IOCL Assam",
    //     product:"IOCL Assam",
    //     category:"oil-and-gas",
    //     image:"assets/website-images/Oil-and-Gas-pics/IOCL-Assam.jpg"
    // },
    // {
    //     id:3,
    //     service:"IOCL Assam",
    //     product:"3d box 1",
    //     category:"oil-and-gas",
    //     image:"assets/website-images/Oil-and-Gas-pics/IOCL-Assam-01.jpg"
    // },
    // {
    //     id:4,
    //     service:"IOCL",
    //     product:"IOCL",
    //     category:"oil-and-gas",
    //     image:"assets/website-images/Oil-and-Gas-pics/IOCL-project-pic.jpg"
    // },
    {
        id:5,
        Data:{
            p1:"“Bedi Group” proudly presents our chain of Indian-style restaurants, offering a unique dining experience that brings the rich flavors and diverse culinary traditions of India to your table.",
            p2:"Our restaurants are spread across various locations, each providing an authentic Indian dining experience. We specialize in a variety of Indian cuisines, from the aromatic biryanis.",
            p3:"“Biryani Bar” is more than just a restaurant; it’s a culinary journey that celebrates the diverse flavors of India. ",
            p4:"",
            image1:"assets/website-images/Oil-and-Gas-pics/biryani.jpeg",
            image2:"assets/website-images/Restaurants/london.jpeg",
        },
        service:"Biryani Bar[London]",
        product:"Biryani Bar",
        category:"Restaurants",
        image:"assets/website-images/Oil-and-Gas-pics/biryani.jpeg",
        span:"Expanding the Biryani Bar franchise, Bedi Group introduces authentic biryani offerings to new locations, enriching dining experiences.",
    },
    {
        id:6,
        Data:{
            p1:"“Bedi Group” proudly presents our chain of Indian-style restaurants, offering a unique dining experience that brings the rich flavors and diverse culinary traditions of India to your table.",
            p2:"Our restaurants are spread across various locations, each providing an authentic Indian dining experience. We specialize in a variety of Indian cuisines, from the aromatic biryanis.",
            p3:"“Biryani Bar” is more than just a restaurant; it’s a culinary journey that celebrates the diverse flavors of India. ",
            p4:"",
            image1:"assets/website-images/Oil-and-Gas-pics/biryani.jpeg",
            image2:"assets/website-images/Restaurants/brantford.jpeg",
        },
        service:"Biryani Bar [Brantford]",
        product:"Biryani Bar",
        category:"Restaurants",
        image:"assets/website-images/Oil-and-Gas-pics/biryani.jpeg",
        span:"Expanding the Biryani Bar franchise, Bedi Group introduces authentic biryani offerings to new locations, enriching dining experiences.",
    },
    {
        id:7,
        Data:{
            p1:"“Bedi Group” proudly presents our chain of Indian-style restaurants, offering a unique dining experience that brings the rich flavors and diverse culinary traditions of India to your table.",
            p2:"Our restaurants are spread across various locations, each providing an authentic Indian dining experience. We specialize in a variety of Indian cuisines, from the aromatic biryanis.",
            p3:"“Biryani Bar” is more than just a restaurant; it’s a culinary journey that celebrates the diverse flavors of India. ",
            p4:"",
            image1:"assets/website-images/Oil-and-Gas-pics/biryani.jpeg",
            image2:"assets/website-images/Restaurants/waterloo.jpeg",
        },
        service:"Biryani Bar[WaterLoo]",
        product:"Biryani Bar",
        category:"Restaurants",
        image:"assets/website-images/Oil-and-Gas-pics/biryani.jpeg",
        span:"Expanding the Biryani Bar franchise, Bedi Group introduces authentic biryani offerings to new locations, enriching dining experiences.",
    },
    {
        id:8,
        Data:{
            p1:"“Bedi Group” proudly presents our chain of Indian-style restaurants, offering a unique dining experience that brings the rich flavors and diverse culinary traditions of India to your table.",
            p2:"Our restaurants are spread across various locations, each providing an authentic Indian dining experience. We specialize in a variety of Indian cuisines, from the aromatic biryanis.",
            p3:"“Biryani Bar” is more than just a restaurant; it’s a culinary journey that celebrates the diverse flavors of India. ",
            p4:"",
            image1:"assets/website-images/Oil-and-Gas-pics/biryani.jpeg",
            image2:"assets/website-images/Restaurants/windsor.jpeg",
        },
        service:"Biryani Bar [Windsor]",
        product:"Biryani Bar",
        category:"Restaurants",
        image:"assets/website-images/Oil-and-Gas-pics/biryani.jpeg",
        span:"Expanding the Biryani Bar franchise, Bedi Group introduces authentic biryani offerings to new locations, enriching dining experiences.",
    },
    {
        id:9,
        Data:{
            p1:"“Bedi Group” proudly presents our chain of Indian-style restaurants, offering a unique dining experience that brings the rich flavors and diverse culinary traditions of India to your table.",
            p2:"Our restaurants are spread across various locations, each providing an authentic Indian dining experience. We specialize in a variety of Indian cuisines, from the aromatic biryanis.",
            p3:"“Biryani Bar” is more than just a restaurant; it’s a culinary journey that celebrates the diverse flavors of India. ",
            p4:"",
            image1:"assets/website-images/Oil-and-Gas-pics/biryani.jpeg",
            image2:"assets/website-images/Restaurants/scarborough.jpeg",
        },
        service:"Biryani Bar [Scarborough]",
        product:"Biryani Bar",
        category:"Restaurants",
        image:"assets/website-images/Oil-and-Gas-pics/biryani.jpeg",
        span:"Expanding the Biryani Bar franchise, Bedi Group introduces authentic biryani offerings to new locations, enriching dining experiences.",
    },
    // {
    //     id:10,
    //     Data:{
    //         p1:"“Bedi Group” proudly presents our chain of Indian-style restaurants, offering a unique dining experience that brings the rich flavors and diverse culinary traditions of India to your table.",
    //         p2:"Our restaurants are spread across various locations, each providing an authentic Indian dining experience. We specialize in a variety of Indian cuisines, from the aromatic biryanis.",
    //         p3:"“Biryani Bar” is more than just a restaurant; it’s a culinary journey that celebrates the diverse flavors of India. ",
    //         p4:"",
    //         image1:"assets/website-images/Oil-and-Gas-pics/biryani.jpeg",
    //         image2:"assets/website-images/Restaurants/angus.jpg",
    //     },
    //     service:"Biryani Bar [Angus]",
    //     product:"Biryani Bar",
    //     category:"Restaurants",
    //     image:"assets/website-images/Oil-and-Gas-pics/biryani.jpeg",
    //     span:"Expanding the Biryani Bar franchise, Bedi Group introduces authentic biryani offerings to new locations, enriching dining experiences.",
    // },

    {
        id:11,
        Data:{
            p1:"“Bedi Group” proudly presents our chain of Indian-style restaurants, offering a unique dining experience that brings the rich flavors and diverse culinary traditions of India to your table.",
            p2:"Our restaurants are spread across various locations, each providing an authentic Indian dining experience. We specialize in a variety of Indian cuisines, from the aromatic biryanis.",
            p3:"“Biryani Bar” is more than just a restaurant; it’s a culinary journey that celebrates the diverse flavors of India. ",
            p4:"",
            image1:"assets/website-images/Oil-and-Gas-pics/biryani.jpeg",
            image2:"assets/website-images/Restaurants/Cambridge.jpeg",
        },
        service:"Biryani Bar [Cambridge]",
        product:"Biryani Bar",
        category:"Restaurants",
        image:"assets/website-images/Oil-and-Gas-pics/biryani.jpeg",
        span:"Expanding the Biryani Bar franchise, Bedi Group introduces authentic biryani offerings to new locations, enriching dining experiences.",
    },
    // {
    //     id:10,
    //     service:"BIM",
    //     product:"BIM",
    //     category:"oil-and-gas",
    //     image:"assets/website-images/BIM-pics/bim5.png"
    // },
    // {
    //     id:11,
    //     service:"BIM",
    //     product:"BIM",
    //     category:"oil-and-gas",
    //     image:"assets/website-images/BIM-pics/bim6.png"
    // },
    // {
    //     id:12,
    //     service:"Samosa",
    //     product:"Samosa",
    //     category:"Restaurants",
    //     image:"src/Images/3productIcon.png"
    // },
    // {
    //     id:13,
    //     service:"Dimsum",
    //     product:"Dimsum",
    //     category:"Restaurants",
    //     image:"src/Images/3productIcon.png"
    // },
    // {
    //     id:14,
    //     service:"Sauces",
    //     product:"Sauces",
    //     category:"Restaurants",
    //     image:"src/Images/3productIcon.png"
    // },
    // {
    //     id:15,
    //     service:"PEB Shed at Malad",
    //     product:"PEB-Shed-at-Angola",
    //     category:"Restaurants",
    //     image:"assets/website-images/Industrial-PEB-Projects/Shed-at-malad.jpeg"
    // },
    // {
    //     id:16,
    //     service:"Real Estate",
    //     product:"3d box 2",
    //     category:"real-estate",
    //     image:"assets/website-images/Realestate-projects-pics/real-estate1.jpeg"
    // },
    // {
    //     id:17,
    //     service:"Real Estate",
    //     product:"3d box 2",
    //     category:"real-estate",
    //     image:"assets/website-images/Realestate-projects-pics/Bungalow-@-Yavatamal.jpg"
    // },
    // {
    //     id:18,
    //     service:"Real Estate",
    //     product:"3d box 2",
    //     category:"real-estate",
    //     image:"assets/website-images/Realestate-projects-pics/Bungalow-@-Yavatamal2.jpg"
    // },
    // {
    //     id:19,
    //     service:"Real Estate",
    //     product:"3d box 2",
    //     category:"real-estate",
    //     image:"assets/website-images/Realestate-projects-pics/Bungalow-@-Yavatamal1.jpg"
    // },
    // {
    //     id:20,
    //     service:"Real Estate",
    //     product:"3d box 2",
    //     category:"real-estate",
    //     image:"assets/website-images/Realestate-projects-pics/Bungalow-@-Yavatamal2.jpg"
    // },
    // {
    //     id:21,
    //     service:"Real Estate",
    //     product:"3d box 2",
    //     category:"real-estate",
    //     image:"assets/website-images/Realestate-projects-pics/real-estate.jpeg"
    // },
    // {
    //     id:22,
    //     service:"Real Estate",
    //     product:"3d box 2",
    //     category:"real-estate",
    //     image:"assets/website-images/Realestate-projects-pics/real-estate2.jpeg"
    // },
    // {
    //     id:23,
    //     service:"Real Estate",
    //     product:"3d box 2",
    //     category:"real-estate",
    //     image:"assets/website-images/Realestate-projects-pics/real-estate3.jpeg"
    // },
    // {
    //     id:24,
    //     service:"Real Estate",
    //     product:"3d box 2",
    //     category:"real-estate",
    //     image:"assets/website-images/Realestate-projects-pics/real-estate4.jpg"
    // },
    // {
    //     id:25,
    //     service:"Real Estate",
    //     product:"3d box 2",
    //     category:"real-estate",
    //     image:"assets/website-images/Realestate-projects-pics/real-estate5.jpg"
    // },
    // {
    //     id:26,
    //     service:"Real Estate",
    //     product:"3d box 2",
    //     category:"real-estate",
    //     image:"assets/website-images/Realestate-projects-pics/shams-residency.jpg"
    // },
    // {
    //     id:27,
    //     service:"Real Estate",
    //     product:"3d box 2",
    //     category:"real-estate",
    //     image:"assets/website-images/Realestate-projects-pics/real-estate6.jpeg"
    // },

    // // .........................Audit
    // {
    //     id:28,
    //     service:"audit",
    //     product:"audit",
    //     category:"tructural-audit",
    //     image:"assets/website-images/structural-audit/NDT Test.jpeg"
    // },
   
    // {
    //     id:29,
    //     service:"audit",
    //     product:"audit",
    //     category:"tructural-audit",
    //     image:"assets/website-images/structural-audit/NDT Test1.jpeg"
    // },
   
    // {
    //     id:30,
    //     service:"audit",
    //     product:"audit",
    //     category:"tructural-audit",
    //     image:"assets/website-images/structural-audit/NDT Test2.jpeg"
    // },
   
    // {
    //     id:31,
    //     service:"audit",
    //     product:"audit",
    //     category:"tructural-audit",
    //     image:"assets/website-images/structural-audit/NDT Test3.jpeg"
    // },
   
    // {
    //     id:32,
    //     service:"audit",
    //     product:"audit",
    //     category:"tructural-audit",
    //     image:"assets/website-images/structural-audit/NDT Test4.jpeg"
    // },
   
    // {
    //     id:33,
    //     service:"audit",
    //     product:"audit",
    //     category:"tructural-audit",
    //     image:"assets/website-images/structural-audit/NDT Test5.jpeg"
    // },
  
    // {
    //     id:26,
    //     service:"Oxygen Plant at Mahul Chembur",
    //     product:"3d box 2",
    //     category:"real-estate",
    //     image:"assets/website-images/Industrial-PEB-Projects/Oxygen-Plant-at-Mahul-Chembur.png"
    // },

    // sus
    // {
    //     id:34,
    //     service:"sus",
    //     product:"sus",
    //     category:"sus",
    //     image:"assets/website-images/Realestate-projects-pics/real-estate1.jpeg"
    // },

    // {
    //     id:35,
    //     service:"sus",
    //     product:"sus",
    //     category:"sus",
    //     image:"assets/website-images/Industrial-PEB-Projects/peb1.jpg"
    // },
  
];
export default ProductsData;