import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import "../../Components/CustomCarousal/index.scss";
// import { structuredData } from './data';
import biryanibar from "../../Images/peb/biryanibar.jpg";
import foundation from "../../Images/header/bg1.jfif";
import foundation2 from "../../Images/header/bg-f.jpg";
import foundation3 from "../../Images/header/child-bg.png";
import foundation4 from "../../Images/header/child-bg1.png"

import structureImage from "../../Images/peb/intel slider images/1.jpg";
import structureImage2 from "../../Images/peb/intel slider images/2.jpg";
import structureImage3 from "../../Images/peb/intel slider images/3.jpg";
import structureImage4 from "../../Images/peb/intel slider images/4.jpg";
import structureImage5 from "../../Images/peb/intel slider images/5.jpg";
import structureImage6 from "../../Images/peb/intel slider images/6.jpg";
import structureImage7 from "../../Images/peb/intel slider images/7.jpg";
import structureImage8 from "../../Images/peb/intel slider images/8.jpg";
import structureImage9 from "../../Images/peb/intel slider images/9.jpg";
import structureImage10 from "../../Images/peb/intel slider images/10.jpg";
import structureImage11 from "../../Images/peb/intel slider images/11.jpg";

export const slidesData = [
  {
    title: "Shelter & Assistance",
    content:
      "Helping homeless and vulnerable elderly individuals find safe accommodation and healthcare support.",
    image: foundation,
  },
  {
    title: "Nutritious Meals",
    content:
      "Providing fresh, healthy meals to ensure every elderly individual receives proper nutrition and care.",
    image: foundation2,
  },
  {
    title: "Education Support",
    content:
      "Creating learning opportunities that inspire children to dream big and achieve success.",
    image: foundation3,
  },
  {
    title: "Empowering Lives",
    content:
      "Providing nutritious meals for a strong and active childhood , Creating opportunities for children to grow, learn, and thrive.",
    image: foundation4,
  },
];



  

  const Slideshow = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [expandedIndex, setExpandedIndex] = useState(-1); // Initialize with no expanded content
  
    useEffect(() => {
      const interval = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex === slidesData.length - 1 ? 0 : prevIndex + 1));
      }, 5500);
  
      return () => clearInterval(interval);
    }, []);
  
    const handleDotClick = (index) => {
      setCurrentIndex(index);
    };
  
    const toggleContent = (index) => {
      setExpandedIndex((prevIndex) => (prevIndex === index ? -1 : index));
    };


   
  
    return (
      <div className="slideshow">
        <div className="activities">
      <h1>Our Contribution</h1>
                    </div>

        <div className="slideshowSlider" style={{ transform: `translate3d(${-currentIndex * 100}%, 0, 0)` }}>
          {slidesData.map((slide, index) => (
            <div key={index} className="slide" style={{ backgroundColor: slide.backgroundColor , padding:"0 20px "}}>
              <Row>
                
                <Col lg={6}>
                  <div className="img-section">
                    <div className="discover-img">
                      <img src={slide.image} alt="slideImage" />
                    </div>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="content-section">
                    <h3>{slide.title}</h3>
                    <div  className={`content-in ${expandedIndex === index ? 'expanded' : ''}`}>
                      {slide.content}
                    </div>
                    {/* <button className="btn btn-primary" onClick={() => toggleContent(index)}>
                      {expandedIndex === index ? "Read Less" : "Read More"}
                      <img src="/static/media/left-arrow.f92d8c5aca7e283c63a2d1691e1dfc8c.svg" alt="arrow" className="btn-arrow"></img>
                    </button> */}
                    
                  </div>
                </Col>
              </Row>
            </div>
          ))}
        </div>
  
        <div className="slideshowDots">
          {slidesData.map((_, index) => (
            <div
              key={index}
              className={`slideshowDot${currentIndex === index ? " active" : ""}`}
              onClick={() => handleDotClick(index)}
            ></div>
          ))}
        </div>
      </div>
    );
  };
  
  export default Slideshow;