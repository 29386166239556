import React from 'react';
import { Link } from 'react-router-dom';
// import styled from 'styled-components';
import { Controller, Scene } from 'react-scrollmagic';
import About from './../Images/core principles/bg.jpg';



const ParallaxSmallBanner = (page) => (
  <div className='over-hide'>
    <Controller globalSceneOptions={{ triggerHook: 'onLeave' }}>
      <Scene pin>
        <div className="panel panel-2 blue"  style={{ backgroundImage: `url(${About})` }} id="popup"><span className='hero-title'><Link to="/">Home</Link> / {page.title}</span></div>
      </Scene>
 
    </Controller>
  </div>
);

export default ParallaxSmallBanner;