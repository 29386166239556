import React from "react";
import { Link, NavLink } from "react-router-dom";
import "font-awesome/css/font-awesome.min.css";
import { IoCallOutline } from "react-icons/io5";

const Footer = () => {
  return (
    <>
      <footer>
        <div className="w-100 ">
          <div className="footer-menu">
            <div className="menu-outer">
              <div className="px-60 py-2">
                <div className="footer-menu-list d-flex justify-content-between align-items-center">
                  <ul className="d-flex">
                    <li>
                      <NavLink
                        to="/about"
                        className={({ isActive }) => (isActive ? "active" : "")}
                      >
                        About Us
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/pharma"
                        className={({ isActive }) => (isActive ? "active" : "")}
                      >
                        Latest Insights
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/careers"
                        className={({ isActive }) => (isActive ? "active" : "")}
                      >
                        Career
                      </NavLink>
                    </li>
                    {/* <li>
                      <NavLink
                        to="/PageNotFound"
                        className={({ isActive }) => (isActive ? "active" : "")}
                      >
                        Contact
                      </NavLink>
                    </li> */}
                  </ul>
                  <div className="footer-menu-list d-flex justify-content-between align-items-center new-social-icon">
                    <span className="social">
                      <a
                        target="_blank"
                        href="https://www.linkedin.com/in/bedi-group-bg-86b814286/"
                        rel="noreferrer"
                      >
                        <i className="fa-brands fa-facebook-f"></i>
                      </a>
                    </span>
                    <span className="social">
                      <a
                        target="_blank"
                        href="https://www.linkedin.com/in/bedi-group-bg-86b814286/"
                        rel="noreferrer"
                      >
                        <i className="fa-brands fa-twitter"></i>
                      </a>
                    </span>
                    <span className="social">
                      <a
                        target="_blank"
                        href="https://www.instagram.com/_bedigroup/"
                        rel="noreferrer"
                      >
                        <i className="fa-brands fa-instagram"></i>
                      </a>
                    </span>
                    <span className="social">
                      <a
                        target="_blank"
                        href="https://www.linkedin.com/in/bedi-group-bg-86b814286/"
                        rel="noreferrer"
                      >
                        <i className="fa-brands fa-linkedin"></i>
                      </a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="footer-bg">
            <div className="main-footer pt-lg-80">
              <div className="row">
                <div className="col-lg-5 ps-50 col-md-6 mb-4 text-start ftr-pd">
                  <div className="footer-heading">
                    <h3>About Us</h3>
                  </div>
                  <div className="footer-desc">
                    <p>
                      At Bedi Group, we bring promises to life and turn visions into accomplishments.
                      Our unwavering dedication to excellence spans diverse industries,
                      fostering opportunities for innovation, growth, and transformation.
                      Through our four dynamic verticals - Real Estate, Food Manufacturing, Restaurants, and Pharma -
                    </p>
                  </div>
                  <div className="footer-address d-flex ">
                    <span className="spanbox">
                    <IoCallOutline style={{ color: '#be8400',   }} /> 
                      </span>

                    <span className="ps-2 ftr-mrg">
                    +1 (709) 687-7904,+1(519) 752-2552
                    </span>
                  </div>
                  <div className="footer-address d-flex ">
                    <span className="spanbox">
                        <i className="bi bi-geo-alt"></i>
                      </span>

                    <span className="ps-2 ftr-mrg">
                      Address: 444 Elgin st, Brantford, ON N3S 7P7, Canada
                    </span>
                  </div>
                  <div className="footer-address d-flex align-items-center">
                    <span>
                      <span className="spanbox">
                        <i className="bi bi-send"></i>
                      </span>
                    </span>
                    <span className="ps-2 ftr-mrg">
                      <a
                        href="ashwani@thebedigroup.com"
                        target="_blank"
                        rel="noreferrer"
                      >
                        ashwani@thebedigroup.com
                      </a>
                    </span>
                  </div>
                </div>
                <div className="col-lg-2 col-md-6 mb-4 text-start">
                  <div className="footer-heading">
                    <h3>Quick Links</h3>
                  </div>
                  <div className="footer-link">
                    <ul className="ps-0">
                      <li>
                        <Link to="/about">About Us</Link>
                      </li>
                      {/* <li>
                        <Link to="/oil-and-gas">
                          Frozen Food</Link>
                      </li> */}

                      <li>
                        <Link to="/products">Products</Link>
                      </li>
                      <li>
                        <Link to="/projects">Past Work</Link>
                      </li>
                      <li>
                        <Link to="/contact">Contact Us</Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-2 col-md-6 mb-4 text-start">
                  <div className="footer-heading">
                    <h3>Sectors</h3>
                  </div>
                  <div className="footer-link">
                    <ul className="ps-0">
                      <li>
                        <Link to="/frozen-food">Frozen Food</Link>
                      </li>
                      <li>
                        <Link to="/restaurant">Restaurant </Link>
                      </li>
                      <li>
                        <Link to="/bg-pharma">Pharma</Link>
                      </li>
                      <li>
                        <Link to="/real-estate">Real Estate</Link>
                      </li>
                      <li>
                        <Link to="/sector-5">B & G Foundation</Link>
                      </li>
                      {/* <li>
                        <Link to="/sustainable-projects">
                          Sustainable Design &amp; Engineering
                        </Link>
                      </li>
                      <li>
                        <Link to="/structural-audit">Structural Audit</Link>
                      </li> */}
                    </ul>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 mb-4 text-start">
                  <div className="footer-heading">
                    <h3>Resources</h3>
                  </div>
                  <div className="footer-link">
                    <ul className="ps-0">
                      <li>
                        <Link to="/">Careers</Link>
                      </li>
                      <li>
                        <Link to="">Inquiry</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="bottom-footer top-border pt-4 d-flex flex-md-row text-center flex-column justify-content-center">
              <div className="footer-copyright pb-4 text-center">
                © 2023 Bedi Group . Designed and Developed by : <a
                        target="_blank"
                        href="https://codekrafters.in/"
                        rel="noreferrer"
                        style={{ textDecoration: 'none', color: 'inherit' }}
                      >
                      CodeKrafters.in
                    </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
