import React from "react";
import { Link } from "react-router-dom";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import { DefaultPlayer as Video } from "react-html5video";
import "react-html5video/dist/styles.css";
import video1 from "./../Images/banner.mp4";
import SecondaryMenu from "./SecondaryMenu";
import Img1 from "./../Images/alain-pham-P_qvsF7Yodw-unsplash.jpg";
import ParallaxSmallBanner from "../SubComponents/ParallaxSmallBanner";
import FlipBook from "./bookflip";
import { HashLink } from "react-router-hash-link";

function GetToKnow(props) {
  return (
    <>
      <Header />

      <div className="scrll">
        <ParallaxSmallBanner img={Img1} title="Get To Know Us" />
      </div>
      <div className="pos-rel">
        <div className="w-100 py-5  pad-60">
          <div className="pt-2 pb-5 text-center">
            <SecondaryMenu linkName={ props.linkName }/>
          </div>
          <div className="row about-container">
            {/* <FlipBook /> */}

            <div className="col-lg-12">
              <div className="contact-heading about-page">
                {/* <div className="video-player mb-4">
                  <h2 className="discover-us">DISCOVER US</h2>
                  <Video autoPlay loop muted controls>
                    <source src={video1} type="video/mp4" />
                  </Video>
                </div> */}
                <p>
                At Bedi Group, we embark on a transformative journey fueled by a vision of excellence and innovation. Our commitment to delivering results sets us apart, as we navigate the intricate landscape of real estate, frozen food, pharmaceuticals, and restaurant franchises. Let us introduce you to the Bedi Difference:                </p>


                <h2>-	The Bedi Difference</h2>
                <p>
                Our unwavering dedication drives us to redefine industry standards. We understand the complexities faced by businesses. Challenges become opportunities, and obstacles are overcome with expertise and determination. At Bedi Group, results are not just a goal; they are our legacy.
                </p>


                <h2>-	Expertise Meets Innovation</h2>
                <p>
                Bedi Group operates globally, yet our commitment to local relevance remains unwavering. We foster connections in key regions worldwide, understanding the nuances of each market. Our international network enables us to bring a global perspective to every project, ensuring sustainable growth and impactful solutions.
                </p>


                <h2>-	Global Reach, Local Impact</h2>
                <p>
                Bedi Group's ethos of 'Global Reach, Local Impact' underscores its commitment to making a difference in communities worldwide. By leveraging its global presence to drive local initiatives and partnerships, Bedi Group is dedicated to creating positive social, economic, and environmental impacts in every location it operates in, enriching lives and fostering sustainable development.
                </p>

                {/* <div className="text-center">
                  <HashLink smooth to="/#popup1" className="nav-btn me-4">
                    Get A Quote
                  </HashLink>
                </div> */}
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
}

export default GetToKnow;
